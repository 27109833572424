import React from "react";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import Local from "../ImageLoader";
import Skeleton from "react-loading-skeleton";
import Image from "next/image";
import Link from "next/link";

const Loading = () => {
  return (
    <div className="flex flex-col">
      <div className="flex gap-x-24">
        <div>
          <span className="font-semibold text-xl leading-9 text-brand-black mb-3 inline-block">
            <Skeleton width={100} height={40} />
          </span>
          <Skeleton count="10" />
        </div>

        <div>
          <span className="font-semibold text-xl leading-9 text-brand-black mb-3 inline-block">
            <Skeleton width={100} height={40} />
          </span>
          <Skeleton count="10" />
        </div>

        <div>
          <span className="font-semibold text-xl leading-9 text-brand-black mb-3 inline-block">
            <Skeleton width={100} height={40} />
          </span>
          <Skeleton count="10" />
        </div>
        <div className="flex flex-col gap-y-3">
          <Skeleton count={1} width={150} height={40} />
          <Skeleton count={1} width={150} height={40} />
        </div>
      </div>
      <div className="flex gap-x-3 mt-20">
        <Skeleton count={1} width={150} height={40} />
        <Skeleton count={1} width={150} height={40} />
      </div>
      <div className="mt-auto flex py-12 border-t gap-x-16">
        <Skeleton count={1} width={150} />
        <Skeleton count={1} width={150} />
        <Skeleton count={1} width={150} />
      </div>
    </div>
  );
};
//TODO: Menu content her render olduğunda datayı tekrar çekiyor.kontrol et
const MenuContent = ({ data, MenuIsOpen }) => {
  const items = data;
  return (
    <div
      className={
        "absolute top-24 left-0 w-full  bg-brand-gray-5 transform transition-all duration-300 shadow-lg " +
        (MenuIsOpen ? "block" : "hidden ")
      }
    >
      <div className="container pt-10 xl:pt-32 flex justify-between w-full">
        <div className="flex flex-col w-full">
          <div className="flex gap-x-24 justify-between xl:justify-start ">
            {items.menu &&
              items.menu.map((item) => (
                <div key={item.id}>
                  <span className="font-semibold text-xl leading-9 text-brand-black mb-3 inline-block">
                    {item.name}
                  </span>
                  <ul className="text-brand-black text-sm gap-y-2 flex flex-col">
                    {item.items &&
                      item.items.map((item) => {
                        const a = {};
                        if (item.nofollow == "0") a.rel = "nofollow";

                        return (
                          <li key={item.id}>
                            <Link href={item.url}>
                              <a
                                className="leading-10 opacity-70 transition-opacity hover:opacity-100"
                                {...a}
                              >
                                {item.name}
                              </a>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ))}

            <div className="flex flex-col gap-y-3">
              {items.mainContent &&
                items.mainContent.map((item) => (
                  <Link href={item.url} key={item.id}>
                    <a
                      title={item.title}
                      className="font-semibold text-xl leading-9 text-brand-black inline-block"
                    >
                      {item.name}
                    </a>
                  </Link>
                ))}
              <div className="flex gap-x-3">
                {items.social.facebook && items.social.facebook !== "" ? (
                  <Link href={items.social.facebook}>
                    <a
                      target={"_blank"}
                      rel={"nofollow"}
                      className="bg-white rounded-full w-9 h-9 flex items-center justify-center text-brand-gray-6 shadow-search"
                    >
                      <FaFacebookF />
                    </a>
                  </Link>
                ) : (
                  ""
                )}
                {items.social.twitter && items.social.twitter !== "" ? (
                  <Link href={items.social.twitter}>
                    <a
                      target={"_blank"}
                      rel={"nofollow"}
                      className="bg-white rounded-full w-9 h-9 flex items-center justify-center text-brand-gray-6 shadow-search"
                    >
                      <FaTwitter />
                    </a>
                  </Link>
                ) : (
                  ""
                )}
                {items.social.instagram && items.social.instagram !== "" ? (
                  <Link href={items.social.instagram}>
                    <a
                      target={"_blank"}
                      rel={"nofollow"}
                      className="bg-white rounded-full w-9 h-9 flex items-center justify-center text-brand-gray-6 shadow-search"
                    >
                      <FaInstagram />
                    </a>
                  </Link>
                ) : (
                  ""
                )}
                {items.social.youtube && items.social.youtube !== "" ? (
                  <Link href={items.social.youtube}>
                    <a
                      target={"_blank"}
                      rel={"nofollow"}
                      className="bg-white rounded-full w-9 h-9 flex items-center justify-center text-brand-gray-6 shadow-search"
                    >
                      <FaYoutube />
                    </a>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-x-3 mt-5 xl:mt-20 mb-2">
            {items.links &&
              items.links.map((item) => {
                const a = {};
                a.rel = "nofollow";

                return (
                  <Link href={item.url} key={item.id}>
                    <a
                      title={item.title}
                      {...a}
                      className={
                        "p-5 rounded-t-sm font-bold text-tiny tracking-1 transition-transform transform hover:scale-10 " +
                        (item.primary === "1"
                          ? "bg-brand-color-primary text-white"
                          : "bg-white text-black")
                      }
                    >
                      {item.name}
                    </a>
                  </Link>
                );
              })}
          </div>
          <div className="mt-auto flex py-12 border-t gap-x-16">
            {items.footerMenu &&
              items.footerMenu.map((item) => {
                const a = {};
                if (item.nofollow == "0") a.rel = "nofollow";
                return (
                  <Link href={item.url} key={item.id}>
                    <a
                      title={item.title}
                      {...a}
                      className="text-brand-black text-tiny leading-9 font-semibold"
                    >
                      {item.name}
                    </a>
                  </Link>
                );
              })}
          </div>
        </div>
        {process.env.NEXT_PUBLIC_SITE != 3 ? (
          <div className="hidden xl:block">
            <div className="max-w-full">
              <Image
                src="/icons/menu-right.jpg"
                width="398"
                height="857"
                alt="Menü resim"
                loader={Local}
              ></Image>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MenuContent;
